<template>
  <div
    :class="
      'card card-custom gutter-b ' +
        (half ? 'card-stretch card-stretch-half' : '')
    "
  >
    <!--begin::Header-->
    <div class="card-header h-auto border-0">
      <div class="card-title py-5">
        <h3 class="card-label text-left">
          <span class="d-block text-dark font-weight-bolder">{{ title }}</span>
          <span class="d-block text-muted mt-2 font-size-lg">
            {{ subtitle }}
          </span>
        </h3>
        <b-icon
          icon="info-circle-fill"
          scale="1.5"
          variant="warning"
          style="position: absolute; right: 30px;"
          id="info"
        ></b-icon>
        <b-tooltip
          target="#info"
          :title="$t('profile.chart.card.info')"
          variant="dark"
        />
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div
      class="card-body p-0 d-flex flex-column"
      :style="{ 'max-height': height }"
    >
      <div v-if="series[0].data.length > 0" class="card-rounded-bottom">
        <apexchart
          type="area"
          :height="height"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
      <div class="row justify-content-center" v-else>
        <div class="col-8">
          <p v-for="(text, index) in $t(empty)" v-bind:key="index">
            {{ text }}
          </p>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { BIconInfoCircleFill } from "bootstrap-vue";
export default {
  components: {
    "b-icon": BIconInfoCircleFill
  },
  name: "Chart",
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    series: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    actions: {
      type: Boolean,
      required: false,
      default: false
    },
    dates: {
      type: Array,
      required: false
    },
    discrete: {
      type: Array,
      required: false
    },
    height: {
      type: String,
      default: "300"
    },
    half: {
      type: Boolean,
      default: false
    },
    empty: {
      type: String,
      default: "profile.chart.empty"
    }
  },
  computed: {
    options() {
      var self = this;
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {},
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: "solid",
          opacity: 0.7
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 2,
          colors: ["#FFA800", "transparent", "transparent"]
        },
        xaxis: {
          categories: this.categories,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
            style: {
              colors: "#B5B5C3",
              fontSize: "12px",
              fontFamily: "Raleway"
            }
          },
          crosshairs: {
            show: false,
            position: "front",
            stroke: {
              color: "#E5EAEE",
              width: 1,
              dashArray: 3
            }
          },
          tooltip: {
            enabled: self.dates && self.dates.length > 0,
            formatter: function(val, index) {
              return self.dates && self.dates[index.dataPointIndex]
                ? self.dates[index.dataPointIndex]
                : null;
            },
            offsetY: 0,
            style: {
              fontSize: "12px",
              fontFamily: "Raleway"
            }
          }
        },
        yaxis: {
          min: -0,
          max: 10,
          labels: {
            show: false,
            style: {
              colors: "#B5B5C3",
              fontSize: "12px",
              fontFamily: "Raleway"
            }
          }
        },
        states: {
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0
            }
          }
        },
        tooltip: {
          style: {
            fontSize: "12px",
            fontFamily: "Raleway"
          },
          y: {
            formatter: function(val) {
              return val !== 0.0001 ? self.$n(val.toFixed(1)) + " / 10" : "";
            }
          }
        },
        colors: ["#FFF4DE", "#EEE5FF", "#F3F6F9"],
        grid: {
          borderColor: "#ECF0F3",
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        markers: {
          colors: ["#FFF4DE", "#EEE5FF", "#F3F6F9"],
          strokeColor: ["#FFA800", "#8950FC", "#B5B5C3"],
          strokeWidth: 3,
          size: 3,
          discrete: self.discrete || []
        }
      };
    }
  }
};
</script>
