<template>
  <!--begin::Tiles Widget 20-->
  <div
    :class="
      'card card-custom gutter-b card-circuled py-0 ' +
        (sameheight ? ' card-stretch' : '')
    "
    :style="sameheight ? '' : 'height: 210px;'"
  >
    <!--begin::Body-->
    <div :class="'card-body ' + (sameheight ? 'd-flex mx-auto pb-0' : '')">
      <!--begin::Chart-->
      <apexchart
        type="radialBar"
        height="250"
        :options="options"
        :series="series"
      ></apexchart>
      <!--end::Chart-->
    </div>
    <b-icon
      v-if="info !== false"
      class="mx-auto mb-10 z-index-1"
      icon="info-circle-fill"
      scale="1.5"
      variant="warning"
      id="gauge-info"
      v-b-tooltip.hover="info"
    ></b-icon>
    <!--end::Body-->
  </div>
  <!--end::Tiles Widget 20-->
</template>

<script>
import {
  getClassByValueAndBase,
  getColorByValueAndBase
} from "@/utils/helpers";
import { BIconInfoCircleFill } from "bootstrap-vue";

export default {
  name: "Gauge",
  components: {
    "b-icon": BIconInfoCircleFill
  },
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    formatter: {
      type: Function,
      required: false,
      default: val => {
        return val;
      }
    },
    background: {
      type: Boolean,
      required: false,
      default: false
    },
    sameheight: {
      type: Boolean,
      required: false,
      default: false
    },
    info: {
      required: false,
      default: false
    }
  },
  computed: {
    classByValue() {
      return (
        getClassByValueAndBase(this.value, 100, false, true) +
        (this.background ? " stripped " : "")
      );
    },
    series() {
      return [this.value];
    },
    formatedNumber() {
      return this.$n(this.value / 100, "percent");
    },
    options() {
      var self = this;
      return {
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,

            hollow: {
              margin: 0,
              size: "70%"
            },
            dataLabels: {
              showOn: "always",
              name: {
                show: true,
                fontSize: "13px",
                fontWeight: "400",
                offsetY: -5,
                color: "#464E5F"
              },
              value: {
                color: "#464E5F",
                fontSize: "22px",
                fontWeight: "bold",
                offsetY: -40,
                show: true,
                formatter() {
                  return self.formatter(self.formatedNumber);
                }
              }
            }
          }
        },
        fill: {
          colors: [getColorByValueAndBase(this.value, 100, true)]
        },
        colors: ["#ffffff"],
        stroke: {
          lineCap: "round"
        },
        labels: [this.title]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.stripped {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='376' height='45.1' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23FFFFFF' stroke-width='0.8' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
}
.z-index-1 {
  z-index: 1;
}
</style>
