<template>
  <b-card class="card-custom" no-body>
    <div class="card-body p-10">
      <b-img-lazy
        fluid
        center
        width="120%"
        src="@/assets/images/icons/security.svg"
        svg-inline
      />
    </div>
    <div class="pb-20 px-20 text-center">
      <h3 class="card-label">
        {{ $t(`empty.${type}.title`) }}
      </h3>
      <span class="text-muted" v-html="$t(`empty.${type}.description`)"> </span>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "EmptyCard",
  props: {
    type: {
      type: String,
      required: false,
      default: "generic"
    }
  }
};
</script>
